<template>
  <a-card :bordered="false">
    <!-- {{ history }} -->
    <div class="result-panel">
      <div v-for="(data, i) in filteredHistory" :key="i" :value="data">
        <span
          :class="[
            `c`,
            data.result.winner.length != 1
              ? `${data.result.winner[2]}`
              : `ZERO`,
          ]"
          v-if="data.result != null"
          >{{ data.result.number }}</span
        >
      </div>
    </div>
  </a-card>
</template>

<script>
export default {
  name: "",
  props: {
    gameType: {
      type: String,
    },
  },
  computed: {
    filteredHistory() {
      return this.history.filter((history) => {
        var date1 = new Date(history.createdAt).getHours();
        var date2 = new Date().getHours();
        // var date1 = "";
        // var date2 = "";

        if (date1 === date2) {
          return history;
        }
      });
    },
  },
  mounted() {
    this.setPath(this.gameType);
  },
  data: function () {
    return {
      history: [],
    };
  },

  methods: {
    setPath(val) {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/game/${val}/history?limit=100&isMock=${this.$store.getters.getMock}`
        )
        .then(({ data }) => {
          this.history = data.reverse();
          console.log(this.history);
        })
        .catch((err) => {
          return err;
        });
    },
    addResult(data) {
      console.log(data);
      this.history.push(data);
    },
  },
};
</script>

<style scoped>
.result-panel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 24px;
  height: 114px;
  align-content: space-between;
}
.c {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;

  text-align: center;
  margin: 2px;
  display: inline-block;
}

.BLACK {
  background: #000000;
}
.ZERO {
  background: #029211;
}

.RED {
  background: #cc0001;
}
</style>
